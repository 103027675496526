import { useQuery } from '@tanstack/vue-query';
import { patientsService } from '@/utils/api/patientsService';

export function useAllPatients() {
  const search = ref('');

  const { data, isLoading } = useQuery({
    queryKey: ['all-patients'],
    queryFn: async () => {
      const data = await patientsService.getAllPatients();

      return data.map((patient) => ({
        ...patient,
        full_name: `${patient.name} ${patient.last_name || ''}`,
      }));
    },
  });

  const searchedPatients = computed(() => {
    if (!data.value) {
      return [];
    }

    if (!search.value) {
      return data.value;
    }

    return data.value.filter((patient) =>
      patient.full_name.toLowerCase().includes(search.value.toLowerCase()),
    );
  });

  return { data, isLoading, search, searchedPatients };
}
